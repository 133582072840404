import React from "react";
import theme from "theme";
import { Theme, Text, Link, Box, Image, Section, Strong, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdAssistant, MdRefresh } from "react-icons/md";
import { FaMoneyBillAlt } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Прокат автомобілів Новий Шлях
			</title>
			<meta name={"description"} content={"Прокат автомобілів Новий Шлях - це не тільки про те, щоб дістатися з пункту А в пункт Б - це про історії, які ви створюєте на цьому шляху. "} />
			<meta property={"og:title"} content={"Головна | Прокат автомобілів Новий Шлях"} />
			<meta property={"og:description"} content={"Прокат автомобілів Новий Шлях - це не тільки про те, щоб дістатися з пункту А в пункт Б - це про історії, які ви створюєте на цьому шляху. "} />
			<meta property={"og:image"} content={"https://visiraindes.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://visiraindes.com/img/3061669.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://visiraindes.com/img/3061669.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://visiraindes.com/img/3061669.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://visiraindes.com/img/3061669.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://visiraindes.com/img/3061669.png"} />
			<meta name={"msapplication-TileImage"} content={"https://visiraindes.com/img/3061669.png"} />
			<meta name={"msapplication-TileColor"} content={"https://visiraindes.com/img/3061669.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="112px 0 0px 0" background="--color-primary" md-padding="96px 0 0px 0" sm-padding="72px 0 0px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				margin="0px 0px 80px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Прокат автомобілів Новий Шлях
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					lg-width="80%"
				>
					Новий Шлях - це не тільки про те, щоб дістатися з пункту А в пункт Б - це про історії, які ви створюєте на цьому шляху. Наш автопарк, хоч і скромний, але ретельно підібраний, щоб забезпечити вам комфортне, надійне та приємне водіння. Куди б не привела вас дорога, ми подбаємо про те, щоб вона запам'яталася вам надовго.
				</Text>
				<Box
					display="flex"
					sm-flex-direction="column"
					sm-width="100%"
					sm-text-align="center"
					justify-content="flex-start"
					align-items="center"
				>
					<Link
						href="/contact-us"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						transition="background-color 0.2s ease-in-out 0s"
						hover-background="--color-orange"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						sm-width="100%"
						hover-transition="background-color 0.2s ease-in-out 0s"
					>
						Почати
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			>
				<Image
					src="https://visiraindes.com/img/key.png"
					width="555px"
					max-width="100%"
					transform="translateY(10px)"
					transition="transform 0.5s ease-in-out 0s"
					hover-transform="translateY(0px)"
					sm-width="100%"
				/>
			</Box>
		</Section>
		<Section
			padding="60px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-15"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 24px 0px"
				sm-justify-content="flex-start"
			>
				<Text
					as="p"
					font="--lead"
					color="--primary"
					margin="0px 0px 8px 0px"
					sm-text-align="left"
				>
					<Strong letter-spacing="0.5px">
						Особливості
					</Strong>
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="--headline2"
					color="--darkL2"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					text-align="center"
					sm-text-align="left"
				>
					Відкрийте для себе Новий Шлях
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--dark"
					width="60%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Наш автопарк достатньо різноманітний, щоб відповідати вашому стилю та потребам, але при цьому ретельно відібраний для забезпечення оптимальної продуктивності та комфорту.
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="24px"
				lg-grid-template-columns="1fr"
				lg-grid-gap="48px"
			>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="md"
								icon={MdAssistant}
								size="20px"
								color="--primary"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong letter-spacing="0.5px">
									Персональний підхід
								</Strong>
							</Text>
						</Box>
						<Text margin="16px 0px 24px 0px" font="--base" lg-max-width="720px">
							Ми пишаємося нашим персональним сервісом. Очікуйте на тепле привітання та автомобіль, який буде виглядати так, ніби його підготували спеціально для вас.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="fa"
								icon={FaMoneyBillAlt}
								size="20px"
								color="--primary"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong>
									Прозоре ціноутворення
								</Strong>
							</Text>
						</Box>
						<Text margin="16px 0px 24px 0px" font="--base" lg-max-width="720px">
							З компанією Новий Шлях ви отримуєте те, що бачите. Ніяких прихованих платежів, ніяких несподіваних платежів.
						</Text>
					</Box>
				</Box>
				<Box display="flex" flex-direction="column" align-items="flex-start" justify-content="space-between">
					<Box>
						<Box align-items="center" display="flex" lg-max-width="720px">
							<Icon
								display="block"
								category="md"
								icon={MdRefresh}
								size="20px"
								color="--primary"
								margin="0px 0 0 0"
							/>
							<Text margin="0px 0px 0px 12px" font="--lead">
								<Strong>
									Гнучкі періоди оренди{" "}
								</Strong>
							</Text>
						</Box>
						<Text margin="16px 0px 24px 0px" font="--base" lg-max-width="720px">
							Незалежно від того, чи потрібен вам автомобіль для швидкого виконання доручення, чи для тривалої подорожі, наші договори оренди настільки ж гнучкі, як і ваші плани.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-6">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				lg-width="100%"
				lg-align-items="center"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="100%"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					width="80%"
					sm-margin="0px 0px 30px 0px"
					sm-width="100%"
					sm-text-align="left"
					sm-font="--headline3"
				>
					Прокат автомобілів Новий Шлях{" "}
					<br />
					- кожeн кілометр відкриває новий шлях
				</Text>
			</Box>
			<Box
				display="flex"
				width="45%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					hover-transform="translateY(-10px)"
					padding="0px 0px 125% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						top={0}
						right={0}
						src="https://visiraindes.com/img/1.jpg"
						display="block"
						width="100%"
						bottom={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						left={0}
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 0px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Клієнтоорієнтований підхід - ми відповімо на кожне питання, вирішимо кожну проблему - ми тут, щоб гарантувати, що ваш досвід прокату буде таким же надійним, як і наші автомобілі.
				</Text>
			</Box>
			<Box
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				display="flex"
				width="55%"
				flex-direction="column"
			>
				<Box
					height="auto"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 45% 0px"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						display="block"
						top="auto"
						right={0}
						bottom="0px"
						src="https://visiraindes.com/img/2.jpg"
						object-fit="cover"
						left={0}
						min-height="100%"
						position="absolute"
						width="100%"
					/>
				</Box>
				<Text
					font="--base"
					lg-text-align="center"
					margin="24px 0px 48px 0px"
					color="--darkL2"
					md-text-align="left"
				>
					Досвід, гідний розповіді - Ми більше, ніж просто послуга - ми перша сторінка вашої дорожньої подорожі.
				</Text>
				<Box
					position="relative"
					padding="0px 0px 45% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
				>
					<Image
						object-fit="cover"
						width="100%"
						bottom="0px"
						min-height="100%"
						src="https://visiraindes.com/img/3.jpg"
						position="absolute"
						display="block"
						top="auto"
						left={0}
						right={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Щоб розпочати наступну главу вашої подорожі з компанією Новий Шлях  або дізнатися про деталі наших послуг, будь ласка, зв'яжіться з нами безпосередньо. Давайте зробимо вашу майбутню подорож історією, яку ви запам'ятаєте.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" quarkly-title="Headline-4">
			<Override slot="SectionContent" sm-min-width="280px" />
			<Box min-width="100px" min-height="100px" display="flex" lg-flex-direction="column">
				<Text
					margin="0px 0px 0px 0px"
					width="50%"
					font="normal 500 52px/1.2 --fontFamily-serifTimes"
					padding="0px 50px 0px 0px"
					lg-width="100%"
					lg-margin="0px 0px 50px 0px"
					md-padding="0px 0 0px 0px"
					md-font="normal 500 42px/1.2 --fontFamily-serifTimes"
					sm-margin="0px 0px 35px 0px"
				>
					Зв'яжіться з нами
				</Text>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--lead" color="#727a82" sm-margin="0px 0px 25px 0px">
						Вирушайте у свою наступну подорож з Новий Шлях  і дозвольте дорогам розповісти вашу особисту історію.
					</Text>
					<Box min-width="10px" min-height="10px" display="flex" sm-flex-direction="column">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							margin="0px 25px 0px 0px"
							sm-margin="0px 0 10px 0px"
						>
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								Телефон
							</Text>
							<Link href="tel:097 750 2393" color="#000000" text-decoration-line="initial" font="--lead">
								097 750 2393
							</Link>
						</Box>
						<Box min-width="10px" min-height="10px" display="flex" margin="0px 0 0px 0px">
							<Text margin="0px 15px 0px 0px" font="--lead" color="#727a82" white-space="nowrap">
								E-mail
							</Text>
							<Link href="mailto:contact@visiraindes.com" color="#000000" text-decoration-line="initial" font="--lead">
								contact@visiraindes.com
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});